<template>
  <div>
    <Spinner :is-loading="!isMapLoaded" />
    <div id="map" />
  </div>
</template>

<script>
import { loadYmap } from "vue-yandex-maps";
import { mapState } from "vuex";
import Spinner from "../spinner/index.vue";

export default {
  name: "HomeMap",
  data() {
    return {
      paginatePageDefault: 500,
      map: null,
      pointsCoords: [],
      myMap: null,
      heatmap: null,
      isMapLoaded: false,
    };
  },
  methods: {
    createMap() {
      try {
        loadYmap({
          apiKey: process.env.VUE_APP_YMAPS_APIKEY,
        }).then(() => {
          if (!document.getElementById("cdn-ymaps-heatmap")) {
            var scriptTag = document.createElement("script");
            scriptTag.src = "https://yastatic.net/s3/mapsapi-jslibs/heatmap/0.0.1/heatmap.min.js";
            scriptTag.id = "cdn-ymaps-heatmap";
            scriptTag.type = "text/javascript";
            scriptTag.async = true;
            scriptTag.defer = true;
            document.getElementsByTagName("head")[0].appendChild(scriptTag);
          }
          setTimeout(() => {
            this.updateMap();
          }, 100);
        });
      } catch (error) {
        console.error(error);
      }
    },
    updateMap() {
      ymaps.modules.require(["Heatmap"], (Heatmap) => {
        this.isMapLoaded = false;
        this.pointsCoords = [];

        if (this.heatmap)
          this.heatmap.setData([]);

        if (!this.orderPoints)
          this.$nextTick(() => {
            this.$store
              .dispatch("getOrderPoints", {
                paginate_page: this.paginatePageDefault,
                page: 1,
                from_date: this.dateFilter ? this.dateFilter?.start : undefined,
                to_date: this.dateFilter ? this.dateFilter?.end : undefined,
              })
              .then(() => {
                this.mapFunction(Heatmap);
              });
          });
        else 
          this.mapFunction(Heatmap);
      });
    },
    mapFunction(Heatmap) {
      for (const point of this.orderPoints.data) {
        if (point.lat && point.long) {
          const tempArr = [Number(point.lat), Number(point.long)];
          this.pointsCoords.push(tempArr);
        }
      }
      this.heatmap = new Heatmap(undefined, {
        radius: 20,
        dissipating: false,
        opacity: 0.6,
        intensityOfMidpoint: 0.15,
        gradient: {
          0: "rgba(128, 255, 0, 0)",
          0.1: "rgba(128, 255, 0, 0.7)",
          0.2: "rgba(255, 255, 0, 0.8)",
          0.7: "rgba(234, 72, 58, 0.9)",
          1: "rgba(162, 36, 25, 1)",
        },
      });
      if (!this.myMap) {
        this.myMap = new ymaps.Map("map", {
          center: this.pointsCoords[0] || [48, 60.1],
          zoom: 7,
          controls: [],
        });
      }
      this.heatmap.setData(this.pointsCoords);
      this.heatmap.setMap(this.myMap);
      this.isMapLoaded = true;
    },
  },
  computed: {
    ...mapState(["orderPoints", "dateFilter"]),
  },
  watch: {
    dateFilter() {
      this.updateMap();
    },
  },
  created() {
    this.createMap();
  },
  mounted() {
   
  },
  components: { Spinner },
};
</script>

<style scoped>
#map {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
</style>
